<template>
    <v-menu 
        v-if="agreement != null" 
        bottom
        v-model="menu"
        :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                small
                :loading="isLoading"
                v-bind="attrs" 
                v-on="on">
                <v-icon small>mdi-handshake-outline</v-icon>
            </v-btn>
        </template>

        <v-list>
            <v-dialog v-if="currentLinkID" width="400">
                <template v-slot:activator="{ on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on" dense>
                        <v-list-item-icon>
                            <v-icon>mdi-share-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            View Link
                        </v-list-item-title>
                    </v-list-item>
                </template>

                <v-card :loading="isEmailing">
                    <v-card-title>Link</v-card-title>
                    <v-card-text>{{ linkMsg }}</v-card-text>
                    <v-card-actions>
                        <v-btn 
                            @click="emailCurrentLink"
                            :loading="isLoading || isEmailing"
                            text>
                            <v-icon left>mdi-email-outline</v-icon>
                            Email Link
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-list-item v-else @click="createLink" dense>
                <v-list-item-icon>
                    <v-icon>mdi-link-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Create And Email Link</v-list-item-title>
            </v-list-item>
        </v-list>
        <BT-Snack v-model="msg" />
    </v-menu>
</template>

<script>
import urlHelpers from '~tools/url-helpers.js';

export default {
    name: 'Customer-Link',
    data: function() {
        return {
            menu: false,
            currentLinkID: null,
            isEmailing: false,
            linkMsg: null,
            msg: null,
            isLoading: false
        }
    },
    props: {
        agreement: null,
        managedAccountID: null,
    },
    methods: {
        async createLink() {
            try {
                var data = { childCompanyID: this.managedAccountID, sendEmail: true };
                this.isLoading = true;
                var res = await this.$BlitzIt.store.post('customer-links', data);
                this.currentLinkID = res.id;
                this.linkMsg = urlHelpers.formLinkURL(res.id);
            }
            catch (err) {
                this.agreement.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                this.isLoading = false;
            }
        }, 
        async emailCurrentLink() {
            if (this.currentLinkID != null) {
                try {
                    this.isEmailing = true;
                    this.isLoading = true;
                    await this.$BlitzIt.api.post('customer-links', null, null, '/post/SendEmail/' + this.currentLinkID);
                    this.isEmailing = false;
                    this.linkMsg = 'Link Emailed';
                }
                catch (err) {
                    this.agreement.errorMsg = this.extractErrorDescription(err);
                }
                finally {
                    this.isLoading = false;
                    this.isEmailing = false;
                }
            }
        } 
    }
}
</script>